import React from 'react';
import P from 'prop-types';

import useDocumentSize from '../../../hooks/useDocumentSize';

import './style/stepsProgressbar.scss';

const propTypes = {
    current: P.number.isRequired,
    activeSteps: P.array.isRequired,
    hasPayment: P.bool.isRequired
};

const StepsProgressBar = ({ current, activeSteps, hasPayment }) => {
    const size = useDocumentSize();

    return (
        <div className="stepProgressBar">
            <div key="stp-1"
                className={`step ${activeSteps.includes(1) ? ( current === 1 ? '': '' )   : (current === 5 ? 'disable-tab-end': 'disable-tab')}`}
                >
                <p className={activeSteps.includes(1) ? ( current === 1 ? 'step-text step-bar current-step' : 'completed' ) : 'step-text'}>
                    {
                        size.width > 768 ? '1. Seus dados' : '1. Dados'
                    }   
                </p>
            </div>
            {hasPayment && <div key="stp-2"
                className={`step ${activeSteps.includes(2) ? ( current === 2 ? '': '' ) : (current === 5 ? 'disable-tab-end': 'disable-tab')}`}
                >
                <p className={activeSteps.includes(2) ? ( current === 2 ? 'step-text step-bar current-step' : 'completed step-text' ) : 'step-text'}>
                    2. Pagamento
                </p>
            </div>}
            <div key="stp-3"
                className={`step ${activeSteps.includes(4) ? ( current === 4 ? '': '' ) : (current === 5 ? 'disable-tab-end': 'disable-tab')}`}
            >
                <p className={activeSteps.includes(4) ? ( current === 4 ? 'step-text step-bar current-step' : 'completed step-text' ) : 'step-text'}>
                    {hasPayment ? '3. Confirmação' : '2. Confirmação'}
                </p>
            </div>
        </div>
    );
};

StepsProgressBar.propTypes = propTypes;
export default StepsProgressBar;