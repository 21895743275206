import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import shortid from 'shortid';

import PropTypes from 'prop-types';
import { notification, Button, Col, Row, Form, Input, Select, Layout, Spin, Collapse } from 'antd';
import MaskedInput from 'antd-mask-input';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import useDocumentSize from '../../hooks/useDocumentSize';
import { getAdressByZipCode } from './helpers';

import { ReactComponent as WhatsAppSvg } from '../../img/svg/whats-app.svg';
import { ReactComponent as ErrorSvg } from '../../img/svg/error.svg';
import CheckGreenSvg from '../../img/svg/check-green.svg';
import { ReactComponent as SendSvg } from '../../img/svg/send.svg';
import { ReactComponent as ArrowRight } from '../../img/svg/arrow-right-lilas.svg';

import { useCheckout } from './shared/CheckoutProvider';
import { noCourse } from './shared/constants';
import { validInputMask, linkWhatsApp, validDate, makeURLGA, validCpf } from './shared/functions';

// Components
import StepPayment from './components/StepPayment';
import SideCard from './components/SideCard';
import TopCard from './components/TopCard';
import StepsProgressBar from './components/StepsProgressBar';
import ValidationError from './components/ValidationError';
import BoxError from './components/BoxError';

// Modais
import ModalExistCPF from './components/ModalExistCPF';
import ModalAlertContract from './components/ModalAlertContract';
import gtm from '../../shared/gtm';

import theme from '../../shared/styled/theme';
import ProvisionOfServiceContract from '../NewCheckout/components/ProvisionOfServiceContract';
import './components/style/style.scss';
import { useSelector } from 'react-redux';
import { differenceInYears, parse } from 'date-fns';
import ModalAlert from '../ModalAlert';
import { saveByStep } from '../../redux/api/checkout';
import { Loading } from '../Loading';

const propTypes = {
  title: PropTypes.any,
  icon: PropTypes.any,
};

const { Option, OptGroup } = Select;
const { Panel } = Collapse;

const { Item } = Form;

function Content() {
  const [current, setCurrent] = useState(1);
  const [errorEmail, setErrorEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [activeSteps, setActiveSteps] = useState([1]);
  const [responseData, setResponseData] = useState({});
  const [responseStatus, setResponseStatus] = useState('');
  const [responseCallback, setResponseCallback] = useState({});
  const [responseAntifraud, setResponseAntifraud] = useState({});
  const [responseMethod, setResponseMethod] = useState(undefined);
  const [openModalCheckCPF, setOpenModalCheckCPF] = useState(false);
  const [openModalAlertContract, setOpenModalAlertContract] = useState(false);
  const [validation, setValidation] = useState({
    visible: false,
    title: '',
    message: '',
  });
  const [openAdressArea, setOpenAdressArea] = useState(false);

  const {
    values,
    setValues,
    course,
    processLoading,
    setChangeValues,
    continueSignIn,
    setContinueSignIn,
    onChangeCEP,
    brazilianStates,
    onFinish,
    onStartCourse,
    onCheckStudent,
    onCheckEmail,
    onSaveByStep,
    storeG4,
    paymentPixStatus,
    cupom
  } = useCheckout();

  const { financialInstitions } = useSelector((state) => state.checkout);

  const query = new URLSearchParams(useLocation().search);
  const useSize = useDocumentSize();

  const { curso, afiliado } = useParams();
  const id_turma = course && course.id_turma ? course.id_turma : '';
  const history = useHistory();

  const forms = Array(5)
    .fill()
    .map(() => {
      const [form] = Form.useForm();
      return form;
    });

  const formCallback = useRef(null);
  const iptEmail = useRef(null);

  useEffect(() => {
    if (query.get('stepIndex') && query.get('status')) {
      setResponseStatus(query.get('status'));
      setActiveSteps([1, 2, 3, 4]);
      setCurrent(parseInt(query.get('stepIndex')));
    }
  }, [query]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (responseStatus && (responseMethod !== "pix" || paymentPixStatus !== "waiting")) {
      if (responseStatus === 'APPROVED' || paymentPixStatus === "paid") {
        makeURLGA(curso, id_turma, 'confirmacao', afiliado);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (['DENIED', 'ERROR'].includes(responseStatus)) {
        makeURLGA(curso, id_turma, 'erro', afiliado);
      }
      gtm();
    } else {
      const steps = {
        1: 'dados',
        2: 'pagamento',
      };

      if (steps[current]) {
        makeURLGA(curso, id_turma, steps[current], afiliado);
      }
      if (steps[current] !== 'dados') {
        gtm();
      }
    }
  // eslint-disable-next-line
  }, [responseStatus, responseMethod, current, curso, afiliado, course]);

  useEffect(() => {
    const canRedirect =
      responseCallback.redirectURL &&
      responseCallback.issuerPaymentId &&
      responseCallback.payerAuthenticationRequest &&
      formCallback.current;

    if (canRedirect) {
      formCallback.current.submit();
    }
  }, [responseCallback]);

  const financialInstitionsMemoized = useMemo(() => financialInstitions, [financialInstitions]);

  useEffect(() => {
    if (course?.id_curso) {
      storeG4(current, openAdressArea);
    }
    // eslint-disable-next-line
  }, [current, course?.id_curso, openAdressArea]);

  useEffect(() => {
    if(paymentPixStatus === "paid"){
      storeG4(4, openAdressArea, "paid");
    }
    // eslint-disable-next-line
  }, [paymentPixStatus]);

  useEffect(() => {
    if(paymentPixStatus === "paid" || paymentPixStatus === "error"){
      setCurrent(current + 1);
      setActiveSteps([current + 1]);
    }
    // eslint-disable-next-line
  },[paymentPixStatus])

  const setStepFinish = () => {
    setCurrent(4);
    setActiveSteps([4]);
  };

  const next = async (stepIndex, event = '') => {
    if (event !== 'finish') {
      onSaveByStep(stepIndex - 1);
    }

    if (!course?.id_turma) {
      return notification['error']({
        message: noCourse,
      });
    }

    const form = forms[current];
    form.submit();

    try {
      await form.validateFields();
    } catch (e) {
      const existEmailError = e.errorFields?.filter((error) => error?.name[0] === 'email');
      if (existEmailError.length) {
        setValidEmail(false);
        setErrorEmail('Por favor informe um email válido');
      }

      // Para remover a validação do form, basta comentar o return.
      return;
    }

    if (!validEmail) {
      setErrorEmail(
        <ValidationError
          errors={[
            'O email já está sendo utilizado no sistema.',
            'Se o email está correto, confira seu cpf.',
          ]}
        />
      );
      return;
    }

    if (current === 1) {
      if (!values.contract || !values.contractPrivacity) {
        return setOpenModalAlertContract(true);
      }
    }

    if (course.valor_curso <= 0 && current === 1) {
      onFinish((resp) => {
        setResponseStatus(resp.status);
        setResponseAntifraud(resp.antifraud);
        setResponseData(resp.data);
        setResponseMethod(resp.method);
        if (resp.method === 'pix') {
          setStepFinish();
        } else {
          if (resp.status === 'PROCESSING') {
            setResponseCallback(resp.callback);
          } else {
            setStepFinish();
          }
        }
      });
    } else {
      if (event === 'finish') {
        onFinish((resp) => {
          setResponseStatus(resp.status);
          setResponseAntifraud(resp.antifraud);
          setResponseData(resp.data);
          setResponseMethod(resp.method);
          if (resp.method === 'credit') {
            setStepFinish();
          } else {
            if (resp.status === 'PROCESSING') {
              setResponseCallback(resp.callback);
            } else {
              setStepFinish();
            }
          }
        });
      } else {
        if (stepIndex === 2 && !continueSignIn) {
          onCheckStudent(({ exist }) => {
            if (exist) {
              setOpenModalCheckCPF(true);
            } else {
              setCurrent(stepIndex);
              const newList = activeSteps.filter((el) => el !== stepIndex);
              setActiveSteps(() => [...newList, stepIndex]);
            }
          });
        } else {
          setCurrent(stepIndex);
          const newList = activeSteps.filter((el) => el !== stepIndex);
          setActiveSteps(() => [...newList, stepIndex]);
        }
      }
    }
  };

  const prev = (stepIndex) => {
    if (stepIndex === 1) setContinueSignIn(false);

    setCurrent(stepIndex);
    if (current === 4) {
      setActiveSteps([1, 2, 3, 4, 5]);
    } else if (current === 1) {
      setActiveSteps([1]);
    } else {
      const newList = activeSteps.slice(0, stepIndex);
      setActiveSteps(() => [...newList]);
    }
  };

  const handleBackModular = () => {
    const isProduction = process.env.NODE_ENV === 'production';
    const url = `${isProduction ? '/checkout' : ''}/modular/cpfmodular`
    history.push(url);
  };

  const handleSendToSupport = () => {
    const error = `Olá, ao tentar realizar meu cadastro no curso ${
      course.curso
    } estou obtendo este erro: ${
      JSON.stringify(responseAntifraud) !== JSON.stringify({})
        ? JSON.stringify(responseAntifraud)
        : JSON.stringify(responseData?.details)
    }`;
    window.open(linkWhatsApp(error), '_blank');
  };

  const handleHelpToSupport = () => {
    window.open(linkWhatsApp(), '_blank');
  };

  const handleNewEmail = () => {
    iptEmail.current.focus();
  };

  const handleChangeEmail = () => {
    return onCheckEmail((exist) => {
      setValidEmail(!exist);
      setErrorEmail(
        <ValidationError
          errors={[
            'O email já está sendo utilizado no sistema.',
            'Se o email está correto, confira seu cpf.',
          ]}
        />
      );
    });
  };

  const handleAddress = async (value) => {
    const cep = value;
    if (cep && !cep.includes('_')) {
      try {
        const result = await getAdressByZipCode(cep);
        if(result?.erro) {
          makeAddressValuesEmpty()
          return
        }
        forms[current].setFieldsValue({
          ...values,
          street: result.logradouro,
          state: result.uf,
          city: result.localidade,
          district: result.bairro,
          ibge: result.ibge,
        });
        onChangeCEP(result);

        forms[current].getFieldInstance('number').focus();
      } catch (e) {
        // Suprimir qualquer falha, o user digita manualmente.
      }
    }
  };

  const makeAddressValuesEmpty = () => {
    const result = {
      logradouro: '',
      uf: '',
      localidade: '',
      bairro: '',
      ibge: '',
    }

    forms[current].setFieldsValue({
      ...values,
      street: result.logradouro,
      state: result.uf,
      city: result.localidade,
      district: result.bairro,
      ibge: result.ibge,
    });
    onChangeCEP(result);
  }

  const handleChangeCep = (value) => {
    forms[current].validateFields(['post_code']).then(() => {
      handleAddress(value);
    }).catch(() => {
      makeAddressValuesEmpty();
    });
  };

  useEffect(() => {
    if (current === 1 && course?.valor_curso === 0 && values.type !== 'pix')
      setValues((oldValues) => ({ ...oldValues, type: 'pix' }));
    // eslint-disable-next-line
  }, [course?.valor_curso, current, values, setValues]);

  async function handleNextStep(current) {
    if (current === 1 && !openAdressArea) {
      setOpenAdressArea(true);
      await saveByStep(values);
      return;
    }
    if (current === 1 && course.valor_curso === 0) {
      next(current + 2, 'finish');
    }
    if (current === 1) {
      const dateTest = parse(values.birth_date, 'dd/MM/yyyy', new Date());
      const age = differenceInYears(new Date(), dateTest);

      if (age < 14) {
        return setValidation({
          visible: true,
          title: 'Você possui menos de 14 anos?',
          message:
            'Envie um email para contato@academiarafaeltoro.com.br para seguir com sua compra',
        });
      } else next(current + 1);
    } else next(current + 1);
  }

  function toggleAlert(visible) {
    setValidation({
      visible,
      message: '',
      title: '',
    });
  }

  const validToEnterAdress =
    !!values?.phone_number &&
    !!values.birth_date &&
    !!values.full_name &&
    !!values.document_number &&
    !!values.email;
  
  if(Object.keys(course).length === 0){
    return <Loading />
  }
    
  if(course?.is_error){
    return <BoxError />
  }
    

  return (
    <>
      <Layout.Content className="content-hero back-grd new-checkout">
        <div className="container">
          <Row className="content-l" gutter={[48, 48]}>
            {/* Se tiver a confirmação do pagamento */}
            {(current === 3 || current === 4) && responseStatus === 'APPROVED' ? (
              <a rel="noopener noreferrer" href='https://www.academiarafaeltoro.com.br/indicatoro' target="_blank" className='top-indicate-area'>
                <div className='left-indicate-area'>
                  <h1>IndicaToro</h1>
                <p>Indique um curso e ganhe cashback!</p>
                </div>
                <div className='right-indicate-area'>
                  <p>Saiba mais</p>
                  <ArrowRight />
                </div>
              </a>
            ) : null}
            {useSize.width <= 1200 && (
              <TopCard current={current} responseStatus={responseStatus} />
            )}

            <Col xs={useSize.width >= 1200 ? '' : 24} span={15}>
              <StepsProgressBar current={current} activeSteps={activeSteps} hasPayment={course.valor_curso > 0} />
              <div>
                <div>
                  <ModalExistCPF
                    isVisible={openModalCheckCPF}
                    setVisible={setOpenModalCheckCPF}
                    handleNewEmail={handleNewEmail}
                    next={() => {
                      setCurrent(current + 1);
                      setActiveSteps([current + 1]);
                    }}
                  />
                  {current === 1 && (
                    <Form form={forms[current]}>
                      <Row>
                        <Col xs={24}>
                          <h5 className="title-steps">Seus dados</h5>
                        </Col>
                      </Row>
                      <Row className="max-container-row-form" gutter={[24, 24]}>
                        <Col className="gutter-row" xs={24} md={12}>
                          <p className="title-form">CPF *</p>
                          <Item
                            className="input-form"
                            name="document_number"
                            onBlur={handleChangeEmail}
                            rules={[
                              { required: true, message: 'Por favor digite o seu CPF.' },
                              () => validCpf('CPF'),
                            ]}
                          >
                            <MaskedInput
                              placeholder="000.000.000-00"
                              value={values.document_number}
                              onChange={(e) => setChangeValues(e.target.value, 'document_number')}
                              mask="111.111.111-11"
                            />
                          </Item>
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                          <p className="title-form">Email * </p>
                          <Item
                            hasFeedback
                            className={`input-form ${
                              !validEmail ? 'ant-form-item-with-help ant-form-item-has-error' : ''
                            }`}
                            name="email"
                            onBlur={handleChangeEmail}
                            rules={[
                              { required: true, message: 'Por favor digite o seu email.' },
                              { type: 'email', message: 'Por favor digite um email válido' },
                            ]}
                            validateStatus={!validEmail && 'error'}
                            help={!validEmail ? errorEmail : ''}
                          >
                            <Input
                              ref={iptEmail}
                              placeholder="Seu e-mail"
                              value={values.email}
                              onChange={(e) => setChangeValues(e.target.value, 'email')}
                            />
                          </Item>
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                          <p className={`title-form ${!validEmail && 'name-label'}`}>
                            Nome completo *
                          </p>
                          <Item
                            className="input-form"
                            name="user_name"
                            rules={[{ 
                              required: true, 
                              message: 'Por favor digite o seu nome.',
                              pattern: new RegExp(/\w\s\w/)
                            }]}
                          >
                            <Input
                              className="in"
                              placeholder="Nome Completo"
                              onChange={(e) => setChangeValues(e.target.value, 'full_name')}
                              value={values.full_name}
                            />
                          </Item>
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                          <p className={`title-form ${!validEmail && 'birthday-date-label'}`}>
                            Data de nascimento *
                          </p>
                          <Item
                            className="input-form"
                            name="birth_date"
                            rules={[
                              { required: true, message: 'Por favor digite o seu nascimento.' },
                              () => validInputMask('data de nacimento', false),
                              () => validDate(),
                            ]}
                          >
                            <MaskedInput
                              placeholder="Dia/Mês/Ano"
                              value={values.birth_date}
                              onChange={(e) => setChangeValues(e.target.value, 'birth_date')}
                              mask="11/11/1111"
                            />
                          </Item>
                        </Col>

                        <Col className="gutter-row" xs={24} md={12}>
                          <p className="title-form">Instituição financeira</p>
                          <Item name="instituicao_financeira" rules={[{ required: false }]}>
                            <Select
                              data-testid="select-finantial-institutions"
                              showSearch
                              placeholder="Escolha..."
                              value={values.instituicao_financeira}
                              onChange={(e) => setChangeValues(e, 'instituicao_financeira')}
                            >
                              <OptGroup data-testid="option-group">
                                {financialInstitionsMemoized.map((banco) => (
                                  <Option key={shortid.generate()} value={banco.nome}>
                                    <span data-testid="option">{banco.nome}</span>
                                  </Option>
                                ))}
                                <Option key="another" value="another">
                                  OUTRA ?
                                </Option>
                              </OptGroup>
                            </Select>
                          </Item>
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                          <p className="title-form">Telefone *</p>
                          <Item
                            className="input-form"
                            name="phone_number"
                            rules={[
                              {
                                required: true,
                                message: 'Por favor digite o seu telefone.',
                              },
                              {
                                pattern: /^\(?\d{2}\)?[\s-]?\d{4}-?\d{4}/,
                                message: 'Aguardando telefone válido',
                              },
                            ]}
                          >
                            <MaskedInput
                              placeholder="(11) 1111-1111"
                              value={values.phone_number}
                              onChange={(e) => setChangeValues(e.target.value, 'phone_number')}
                              maxLength="11"
                              mask="(11)1111-11111"
                            />
                          </Item>
                        </Col>
                        {values.instituicao_financeira === 'another' && (
                          <Col className="gutter-row" xs={24} md={12}>
                            <p className="title-form">Outra instituição *</p>
                            <Item
                              className="input-form"
                              name="another_financial_instituition"
                              rules={[
                                values.instituicao_financeira === 'another'
                                  ? {
                                      required: true,
                                      message: 'Por favor informa qual a instituição',
                                    }
                                  : {},
                              ]}
                            >
                              <Input
                                placeholder="Outra.."
                                value={values.another_financial_instituition}
                                onChange={(e) =>
                                  setChangeValues(
                                    e.target.value,
                                    'another_financial_instituition'
                                  )
                                }
                              />
                            </Item>
                          </Col>
                        )}
                      </Row>
                      <Collapse
                        className="take-off"
                        activeKey={!openAdressArea ? ['1'] : ['2']}
                        accordion
                      >
                        <Panel
                          className="take-off"
                          disabled={!validToEnterAdress}
                          header=""
                          key="2"
                        >
                          <Row>
                            <Col xs={24}>
                              <h5 className="title-steps">Seu endereço</h5>
                            </Col>
                          </Row>
                          <Row className="max-container-row-form" gutter={[24, 24]}>
                            <Col className="gutter-row" xs={24} md={12}>
                              <h5 className="title-form">CEP *</h5>
                              <Item
                                className="input-form"
                                name="post_code"
                                rules={[
                                  { required: true, message: 'Por favor digite o CEP' },
                                  () => validInputMask('CEP'),
                                ]}
                              >
                                <MaskedInput
                                  placeholder="CEP"
                                  value={values.post_code}
                                  onKeyUp={(e) => handleChangeCep(e.target.value)}
                                  onChange={(e) => setChangeValues(e.target.value, 'post_code')}
                                  mask="11111-111"
                                  disabled={!validToEnterAdress}
                                />
                              </Item>
                              <Item name="ibge" hidden>
                                <Input type="text" value={values.ibge} />
                              </Item>
                            </Col>
                            <Col className="gutter-row" xs={24} md={12}>
                              <h5 className="title-form">UF *</h5>
                              <Item
                                name="state"
                                rules={[
                                  { required: true, message: 'Por favor escolha seu estado.' },
                                ]}
                              >
                                <Select
                                  optionFilterProp="children"
                                  placeholder="Escolha.."
                                  value={values.state}
                                  onChange={(e) => setChangeValues(e, 'state')}
                                  disabled={!validToEnterAdress}
                                  options={brazilianStates.map((state) => ({ label: state.nome + " / " + state.sigla, value: state.sigla }))}
                                />
                              </Item>
                            </Col>

                            <Col className="gutter-row" xs={24} md={12}>
                              <h5 className="title-form">Cidade *</h5>
                              <Item
                                className="input-form"
                                name="city"
                                rules={[
                                  { required: true, message: 'Por favor digite sua cidade.' },
                                ]}
                              >
                                <Input
                                  placeholder="Cidade"
                                  value={values.city}
                                  onChange={(e) => setChangeValues(e.target.value, 'city')}
                                  disabled={!validToEnterAdress}
                                />
                              </Item>
                            </Col>

                            <Col className="gutter-row" xs={24} md={12}>
                              <h5 className="title-form">Bairro *</h5>
                              <Item
                                className="input-form"
                                name="district"
                                rules={[{ required: true, message: 'Por favor digite o bairro' }]}
                              >
                                <Input
                                  placeholder="Bairro"
                                  value={values.district}
                                  onChange={(e) => setChangeValues(e.target.value, 'district')}
                                  disabled={!validToEnterAdress}
                                />
                              </Item>
                            </Col>

                            <Col className="gutter-row" xs={24} md={12}>
                              <h5 className="title-form">Rua *</h5>
                              <Item
                                className="input-form"
                                name="street"
                                rules={[{ required: true, message: 'Por favor digite sua rua.' }]}
                              >
                                <Input
                                  placeholder="Rua"
                                  value={values.street}
                                  onChange={(e) => setChangeValues(e.target.value, 'street')}
                                  disabled={!validToEnterAdress}
                                />
                              </Item>
                            </Col>
                            <Col className="gutter-row" xs={24} md={6}>
                              <h5 className="title-form">Número *</h5>
                              <Item
                                className="input-form"
                                name="number"
                                rules={[{ required: true, message: 'Informe o número' }]}
                              >
                                <Input
                                  placeholder="000"
                                  value={values.number}
                                  onChange={(e) => setChangeValues(e.target.value, 'number')}
                                  disabled={!validToEnterAdress}
                                />
                              </Item>
                            </Col>
                            <Col className="gutter-row" xs={24} md={6}>
                              <h5 className="title-form">Complemento</h5>
                              <Item className="input-form" name="complement">
                                <Input
                                  placeholder="Complemento"
                                  value={values.number}
                                  onChange={(e) => setChangeValues(e.target.value, 'complement')}
                                  disabled={!validToEnterAdress}
                                />
                              </Item>
                            </Col>
                          </Row>
                        </Panel>
                        <Col>
                          <ProvisionOfServiceContract />
                          <ModalAlertContract
                            isVisible={openModalAlertContract}
                            setVisible={setOpenModalAlertContract}
                          />
                        </Col>
                      </Collapse>
                    </Form>
                  )}
                  {/* PAGAMENTO */}

                  {current === 2 && (
                    <>
                      <form
                        ref={formCallback}
                        method="POST"
                        action={responseCallback.redirectURL}
                        id="form_s"
                      >
                        <input type="hidden" name="MD" value={responseCallback.issuerPaymentId} />
                        <input
                          type="hidden"
                          name="PaReq"
                          value={responseCallback.payerAuthenticationRequest}
                        />
                        <input
                          type="hidden"
                          name="TermUrl"
                          value={responseCallback.backendCallback}
                        />
                      </form>
                      <Form form={forms[current]}>
                        <StepPayment onFinish={() => next(3, 'finish')} form={forms[current]} />
                      </Form>
                    </>
                  )}
                  {/* CONFIRMAÇÂO */}
                  {(((current === 3 || current === 4) && responseStatus === 'APPROVED') || paymentPixStatus === "paid") && (
                    <Form form={forms[current]}>
                      <h5 className="title-steps-last">
                        <img
                          src={CheckGreenSvg}
                          style={useSize.width >= 376 ? { width: '40px', height: '45px' } : {}}
                          alt="Compra confirmada"
                        />{' '}
                        {course?.valor_curso <= 0 || cupom?.percentage === 100 ? "Matrícula confirmada :)" : "Compra confirmada :)"}{' '}
                      </h5>
                      <div style={useSize.width > 376 ? { marginLeft: '45px' } : {}}>
                        <h1 className="title-description-last-step">
                          <strong>Tudo certo com a sua inscrição!</strong>{' '}
                        </h1>
                        <p className="description-last-step">
                          As informações de login foram enviadas para o seu e-mail cadastrado!{' '}
                        </p>
                        <p className="description-last-step-strong">
                          <strong>
                            O seu login é o seu e-mail cadastrado e sua senha corresponde ao seu
                            CPF (somente os números).
                          </strong>
                        </p>
                        <p className="description-last-step">
                          {' '}
                          Você também receberá estas informações por e-mail. Caso a mensagem não
                          esteja na sua caixa de entrada, verifique a sua pasta de spam.
                        </p>
                        {useSize.width > 375 && (
                          <>
                            <p className="description-last-step contact-whats-app">
                              &nbsp;Em caso de dúvidas, entre em contato pelo nosso
                            </p>
                            <p className="description-last-step whats-app-number">
                              &nbsp;WhatsApp (51) 99744-9565
                            </p>
                            <button className="start-course" onClick={() => onStartCourse()}>
                              Começar o curso <RightOutlined />
                            </button>
                          </>
                        )}
                      </div>
                      {useSize.width <= 375 && (
                        <Col
                          className="gutter-row btn-col-content btn-next"
                          style={
                            current > 1
                              ? {
                                  display: 'flex',
                                  paddingLeft: '0px',
                                  justifyContent: 'end',
                                  left: '-14px',
                                }
                              : { left: '-14px', display: 'flex', justifyContent: 'end' }
                          }
                          xs={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                        >
                          <button
                            className={current === 4 && 'btn-default-whats'}
                            onClick={() => onStartCourse()}
                          >
                            Começar o curso
                            <RightOutlined />
                          </button>
                        </Col>
                      )}
                    </Form>
                  )}
                  {((current === 4 && ['DENIED', 'ERROR'].includes(responseStatus)) || paymentPixStatus === "error") && (
                    <Row className="row-failed">
                      <Col>
                        <p className="title-failed-payment">
                          <ErrorSvg /> Falha no pagamento :({' '}
                        </p>
                        <p className="description-failed-payment">
                          Não conseguimos efetuar o seu pagamento!
                        </p>
                        <p className="text-failed-payment">
                          Tente novamente mais tarde ou entre em contato com a gente pelo e-mail:
                        </p>
                        <p className="contact-failed-payment">
                          contato@academiarafaeltoro.com.br
                        </p>
                        {useSize.width >= 376 &&
                        (responseData?.details?.length ||
                          JSON.stringify(responseAntifraud) !== JSON.stringify({})) ? (
                          <>
                            <button className="btn-send-message" onClick={handleSendToSupport}>
                              Enviar erro para o suporte <SendSvg />
                            </button>
                          </>
                        ) : (
                          useSize.width > 376 && (
                            <button className="btn-support-help" onClick={handleHelpToSupport}>
                              <WhatsAppSvg /> Pedir ajuda para o suporte
                            </button>
                          )
                        )}
                        {useSize.width <= 375 && (
                          <button className="btn-support-help" onClick={handleHelpToSupport}>
                            <WhatsAppSvg /> Fale com a gente!
                          </button>
                        )}
                      </Col>
                    </Row>
                  )}
                </div>

                <Row className="buttons-back-next row-md" gutter={[24, 24]}>
                  {(current > 1 && current <= 3 && paymentPixStatus === "waiting") && (
                    <Col className="gutter-row btn-back" xs={24} md={12} lg={12}>
                      <Button className="btn-default-white" onClick={() => prev(current - 1)}>
                        <LeftOutlined />
                        Voltar
                      </Button>
                    </Col>
                  )}
                  {current < 3 && (
                    <>
                      {current === 1 && !openAdressArea ? (
                        <>
                          {course?.id_type_course_class === 2 &&
                            useSize.width > theme.media.tablet && (
                              <Col className="btn-back-one" xs={24} md={12} lg={12}>
                                <Button className="btn-default-white" onClick={handleBackModular}>
                                  <LeftOutlined />
                                  Voltar
                                </Button>
                              </Col>
                            )}
                          <Col
                            className={current > 1 ? 'btn-next' : 'btn-next-one'}
                            xs={24}
                            md={course?.id_type_course_class === 2 ? 12 : 24}
                            lg={course?.id_type_course_class === 2 ? 12 : 24}
                            xl={course?.id_type_course_class === 2 ? 12 : 24}
                            xxl={course?.id_type_course_class === 2 ? 12 : 24}
                          >
                            <Button
                              className={
                                current === 4
                                  ? 'disable-button'
                                  : !validToEnterAdress
                                  ? 'btn-disabled-next'
                                  : 'btn-default-orange'
                              }
                              onClick={() => handleNextStep(current)}
                              disabled={!validToEnterAdress}
                              style={{marginLeft: "auto"}}
                            >
                              Avançar
                              <RightOutlined />
                            </Button>
                          </Col>
                          {course?.id_type_course_class === 2 &&
                            useSize.width <= theme.media.tablet && (
                              <div className="btn-back-one">
                                <Button onClick={handleBackModular}>
                                  <LeftOutlined />
                                  Voltar
                                </Button>
                              </div>
                            )}
                        </>
                      ) : course.valor_curso === 0 && current === 1 && openAdressArea ? (
                        <Col
                          className={'btn-next-one'}
                          xs={24}
                          md={course?.id_type_course_class === 2 ? 12 : 24}
                          lg={course?.id_type_course_class === 2 ? 12 : 24}
                          xl={course?.id_type_course_class === 2 ? 12 : 24}
                          xxl={course?.id_type_course_class === 2 ? 12 : 24}
                        >
                          <Button
                            className={'btn-default-orange'}
                            onClick={() => handleNextStep(current)}
                          >
                            <div className="btn-content-process">
                                {!processLoading ? (
                                  <>
                                    <span className="btn-content-process-text" style={{marginLeft: "auto"}}>Finalizar</span>
                                    <RightOutlined />
                                  </>
                                ) : (
                                  <Spin indicator={<LoadingOutlined spin />} />
                                )}
                            </div>
                          </Button>
                        </Col>
                      ) : course.valor_curso > 0 && current === 1 && openAdressArea ? (
                        <Col
                            className={current > 1 ? 'btn-next' : 'btn-next-one'}
                            xs={24}
                            md={course?.id_type_course_class === 2 ? 12 : 24}
                            lg={course?.id_type_course_class === 2 ? 12 : 24}
                            xl={course?.id_type_course_class === 2 ? 12 : 24}
                            xxl={course?.id_type_course_class === 2 ? 12 : 24}
                          >
                        <Button
                        className={
                          current === 4
                            ? 'disable-button'
                            : !validToEnterAdress
                            ? 'btn-disabled-next'
                            : 'btn-default-orange'
                        }
                        onClick={() => handleNextStep(current)}
                        disabled={!validToEnterAdress}
                        style={{marginLeft: "auto"}}
                      >
                        Avançar
                        <RightOutlined />
                      </Button>
                      </Col>
                      ) : (
                        <Col
                          className={current > 1 ? 'btn-next' : 'btn-next'}
                          xs={24}
                          md={12}
                          lg={12}
                        >
                          {values.type !== 'pix' && (
                            <Button
                              type="submit"
                              className="btn-default-orange"
                              onClick={() => next(current + 1, 'finish')}
                              disabled={processLoading}
                            >
                              <div className="btn-content-process">
                                {!processLoading ? (
                                  <>
                                    <span className="btn-content-process-text" style={{marginLeft: "auto"}}>Finalizar</span>
                                    <RightOutlined />
                                  </>
                                ) : (
                                  <Spin indicator={<LoadingOutlined spin />} />
                                )}
                              </div>
                            </Button>
                          )}
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              </div>
            </Col>
            {useSize.width > 1200 && (
              <SideCard current={current} responseStatus={responseStatus} paymentPixStatus={paymentPixStatus} />
            )}
          </Row>
        </div>
      </Layout.Content>

      <ModalAlert
      isVisible={validation.visible}
      setVisible={() => toggleAlert(false)}
      title={validation.title}
      message={validation.message}
      />
    </>
  );
}

Content.propTypes = propTypes;
export default Content;
