import React from 'react';

import './styles.scss';

import { ReactComponent as WhatsAppSvg } from '../../../../img/svg/whats-app.svg';

import config from '../../../../config';

const { LINK_CONTACT_URL } = config;

export default function BoxError({ type = 'course' }: BoxErrorProps) {
    return (
        <div className="error-not-found" data-testid="error-not-found-testid">
            <p className="message-error">
                {messagesError[type].firstMessage} <span>diretamente com nosso time</span>!
            </p>
            <p className="sub-message-error">{messagesError[type].secondMessage}</p>
            <a
                className="button-resolve-problem"
                href={LINK_CONTACT_URL}
                target="_blank"
                rel="noopener noreferrer"
            >
                Entre em contato para comprar <WhatsAppSvg />
            </a>
        </div>
    );
}

const messagesError = {
    course: {
        firstMessage: 'Sua escolha está disponível para compra',
        secondMessage:
            'Para garantir a compra entre em contato com time da ART. Nós te ajudamos nisso!',
    },
    module: {
        firstMessage: 'Essa combinação de módulos está disponível para compra',
        secondMessage:
            'Para garantir a compra dos módulos que você escolheu, entre em contato com time da ART. Nós te ajudamos nisso!',
    },
};

interface BoxErrorProps {
    type?: 'course' | 'module';
}
