import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import { Footer, Header } from '../../components/NewCheckout';
import CheckoutProvider from '../../components/NewCheckout/shared/CheckoutProvider';
import { getDataByDomain } from '../../utils';

import { Container } from './styles';

type Props = {
  children: ReactNode
};

function Checkout({ children }: Props) {
  const { hasMenu } = getDataByDomain();

  return (
      <CheckoutProvider>
          <Helmet>
              <meta charSet="utf-8" />
              <meta name="robots" content="noindex" />
              <title>Finalizar compra - Rafael Toro - Academia de Finanças</title>
          </Helmet>

          <Container>
              <Header />
              {children}
              {hasMenu && <Footer />}
          </Container>
      </CheckoutProvider>
  );
}

export default Checkout;
