import './components/style/footer.scss';
import React from 'react';
import { Button, Col, Divider, Layout, Row } from 'antd';
import { Typography } from 'antd';

import {
  YoutubeFilled,
  WhatsAppOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import FacebookIcon from './assets/FacebookIcon';
import LinkedinIcon from './assets/LinkedinIcon';
import useDocumentSize from '../../hooks/useDocumentSize';
import TiktokIcon from './assets/TiktokIcon';


function Footer() {
  const { Footer: AntFooter } = Layout;
  const useSize = useDocumentSize();

  return (
    <AntFooter className="checkout-footer">
      <Row id="contact-area">
        <Col className="checkout-logo-container" lg={{ span: 4 }} xs={{ span: 6 }}>
          <img
            className="checkout-footer-logo"
            src={`${process.env.PUBLIC_URL}/assets/img/toro-vertical-white.svg`}
            alt="Logo Rafael Toro Academia"
          />
        </Col>
        <Col
          className="checkout-footer-data-container"
          xl={{ span: 14, offset: 1 }}
          lg={{ span: 12, offset: 2 }}
          xs={{ span: 24 }}
        >
          <Typography.Title level={5} className="checkout-footer-data-title">
            {new Date().getFullYear()} © RAFAEL TORO, ACADEMIA DE FINANCAS LTDA
          </Typography.Title>
          <Typography.Paragraph className="checkout-footer-data-text">
            CNPJ 30.425.807/0001-37 • Rua Dona Laura, 320 - sala 801, Moinhos de Vento, Porto Alegre, RS
          </Typography.Paragraph>
          <Typography.Paragraph className="checkout-footer-data-contact">
            contato@academiarafaeltoro.com.br • (51) 99744-9565
          </Typography.Paragraph>
          <Typography.Paragraph className="checkout-footer-data-text">
          Atendimento: Segunda a sexta, das 10h às 22h. 
          Sábado e domingo, das 10h às 18h.
          </Typography.Paragraph>
        </Col>
        <Col
          span="4"
          // offset="5"
          xl={{ span: 5, offset: 0 }}
          lg={{ span: 4, offset: 2 }}
          sm={{ span: 10, offset: 8 }}
          xs={{ span: 14, offset: 4 }}
          className="checkout-footer-contact-container"
        >
          <Button
            icon={<WhatsAppOutlined />}
            size="large"
            shape="round"
            target="_blank"
            className="checkout-footer-contact-button"
            href="https://www.academiarafaeltoro.com.br/whatsapp"
          >
            Fale com a gente!
          </Button>
        </Col>
      </Row>
      <Divider className="checkout-footer-divider" />
      <Row>
        <Col
          // span="11"
          // offset="6"
          xl={{ span: useSize.width >= 1200 ? 11 : 18, offset: 5 }}
          lg={{ span: 24 }}
          xs={{ span: 24 }}
          className="checkout-footer-links-container"
        >
          <div className="checkout-footer-links-area toro-academy">
            <Typography.Text className="checkout-footer-links-title">Institucional</Typography.Text>
            <a
              className="checkout-footer-links-element"
              href="https://app2.academiarafaeltoro.com.br/"
            >
              Área do aluno
            </a>
            <a
              href="https://www.academiarafaeltoro.com.br/contato/"
              className="checkout-footer-links-element"
            >
              Fale conosco
            </a>
            <a
              href="https://www.academiarafaeltoro.com.br/faq/"
              className="checkout-footer-links-element"
            >
              FAQ
            </a>
            <a
              href="https://www.academiarafaeltoro.com.br/sobre/"
              className="checkout-footer-links-element"
            >
              Sobre a Academia Rafael Toro
            </a>
          </div>
          <div className="checkout-footer-links-area conteudo">
            <Typography.Text className="checkout-footer-links-title">Conteúdo</Typography.Text>
            <a
              className="checkout-footer-links-element"
              href="https://www.academiarafaeltoro.com.br/cursos/"
            >
              Cursos
            </a>
            <a
              className="checkout-footer-links-element"
              href="https://www.academiarafaeltoro.com.br/blog/"
            >
              Blog
            </a>
            <a
              className="checkout-footer-links-element"
              href="https://www.academiarafaeltoro.com.br/materiais/"
            >
              Materiais
            </a>
          </div>
          <div className="checkout-footer-links-area legal">
            <Typography.Text className="checkout-footer-links-title">Legal</Typography.Text>
            <a
              className="checkout-footer-links-element"
              href="https://www.academiarafaeltoro.com.br/termos-de-uso/"
            >
              Termos de uso
            </a>
            <a
              className="checkout-footer-links-element"
              href="https://www.academiarafaeltoro.com.br/direitos-autorais/"
            >
              Direitos autorais
            </a>
            <a
              className="checkout-footer-links-element"
              href="https://www.academiarafaeltoro.com.br/politica-de-privacidade/"
            >
              Política de privacidade
            </a>
          </div>
          {useSize.width < 1200 && (
            <div className="checkout-footer-social-media-container">
              <Typography.Text className="checkout-footer-links-title">
                Redes sociais
              </Typography.Text>
              <div className="checkout-footer-social-media-content">
                <Button
                  icon={<InstagramOutlined />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://www.instagram.com/academiarafaeltoro/"
                />
                <Button
                  icon={<YoutubeFilled />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://www.youtube.com/channel/UCPgdSF873lbEObhyaHsXnrw"
                />
                <Button
                  icon={<FacebookIcon />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://www.facebook.com/academiarafaeltoro"
                />
                <Button
                  icon={<TwitterOutlined />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://twitter.com/professor_toro"
                />
                <Button
                  icon={<LinkedinIcon />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://www.linkedin.com/company/academiarafaeltoro/"
                />
                <Button
                  icon={<TiktokIcon />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://www.tiktok.com/@academiarafaeltoro"
                />
              </div>
            </div>
          )}
        </Col>
        {useSize.width >= 1200 && (
          <Col span="5" offset="3">
            <div className="checkout-footer-social-media-container">
              <Typography.Text className="checkout-footer-links-title">
                Redes sociais
              </Typography.Text>
              <div className="checkout-footer-social-media-content">
                <Button
                  icon={<InstagramOutlined />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://www.instagram.com/academiarafaeltoro/"
                />
                <Button
                  icon={<YoutubeFilled />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://www.youtube.com/channel/UCPgdSF873lbEObhyaHsXnrw"
                />
                <Button
                  icon={<FacebookIcon />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://www.facebook.com/academiarafaeltoro"
                />
                <Button
                  icon={<TwitterOutlined />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://twitter.com/professor_toro"
                />
                <Button
                  icon={<LinkedinIcon />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://www.linkedin.com/company/academiarafaeltoro/"
                />
                <Button
                  icon={<TiktokIcon />}
                  shape="circle"
                  size="large"
                  target="_blank"
                  className="checkout-footer-social-media-element"
                  href="https://www.tiktok.com/@academiarafaeltoro"
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </AntFooter>
  );
}

export default Footer;
