import React, { HTMLAttributes } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Container } from './styles';

type Props = HTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    antIcon?: () => void;
    layout?: 'warning' | 'primary' | 'outline-primary' | 'waiting' | 'default';
    isLoading?: boolean;
    disabled?: boolean;
};

const Button = ({
    children, 
    antIcon, 
    layout, 
    isLoading, 
    disabled, 
    ...rest
}: Props) => {

    return (
        <Container {...rest} layout={layout ?? 'default'} icon={antIcon ? true : false} disabled={disabled} className={`btn-${layout}`}>
            <>
                {isLoading ? (
                    <Spin data-testid="loader" indicator={<LoadingOutlined spin />} />
                ) : (
                    <>
                        {
                            antIcon && (
                                antIcon()
                            )
                        }
                        {children}
                    </>
                )}
            </>
        </Container>
    )
};

export default Button;