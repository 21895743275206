import config from '../config';

// Used for local data storage when 'localStorage' is unavailable
const memoryStore = {
  getItem(key, value) {
    return this[`_${key}`]
  },
  setItem(key, value) {
    this[`_${key}`] = value
  }
}

const storageAvailable = type => {
  try {
    var storage = window[type]
    var x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (error) {
    console.warn(
      "Your browser has disabled or does not support 'localStorage'.\n" +
        "Please enable 'localStorage' or switch to a browser that supports Web Storage\n" +
        'features to prevent potential issues while using this site.'
    )
    return false
  }
}

export function filterOptionDefault(inputValue, option) {
  return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
}

export const dataStore = storageAvailable('localStorage') ? window.localStorage : memoryStore

export function getDataByDomain() {
  const { dataByDomain } = config;
  const hostname = window.location.hostname; 
  const domainKey = Object.keys(dataByDomain).find(i => hostname.endsWith(i)) || 'default';

  return dataByDomain[domainKey] || {};
}