import React, { useEffect, useState, createContext, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { notification } from 'antd';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { actions as checkoutActions } from '../../../redux/modules/checkout';
import { actions as authActions } from '../../../redux/modules/auth';

// Models Login
import config from '../../../config';


import { getNameAndLastName } from '../../../shared/utils/common';
import CheckoutG4 from './CheckoutG4';
import {
  makeInstallments,
  encryptCard,
  unformat,
  formatExpirionNumber,
  formatCardCreditType,
  formatBirthDate,
  makeURLToken,
  getCookie,
  setCookie,
  getFullCurrentValue,
} from './functions';
import { getDataByDomain } from '../../../utils';

const cardInitialState = {
  type: '',
  brand: '',
  card_number: '',
  expiration_month: '',
  expiration_year: '',
  holder_document: '',
  holder_name: '',
  security_code: '',
};

const initialState = {
  curso: '',
  id_turma: '',
  full_name: '',
  email: '',
  phone_number: '',
  birth_date: '',
  document_number: '',
  instituicao_financeira: '',
  another_financial_instituition: '',
  city: '',
  state: '',
  street: '',
  number: '',
  district: '',
  post_code: '',
  type: 'credit',
  expiry: '',
  cupom: '',
  card: { ...cardInitialState },
  installments: '',
  afiliado: '',
  complement: '',
  contract: false,
  contractPrivacity: false,
  detailsCupom: {
    valid: false,
    cashValue: 0,
    value: 0,
  },
  selectedModules: []
};

export const CheckoutContext = createContext({});

const CheckoutProvider = ({ children }) => {
  const { curso, id_turma: idTurma, afiliado } = useParams();
  const history = useHistory();

  const [values, setValues] = useState({
    ...initialState,
    curso,
    afiliado: afiliado ? decodeURIComponent(afiliado) : '',
  });

  const {
    cupom,
    course,
    applyCupomLoading,
    proccessLoading,
    brazilianStates,
    financialInstitions,
    currentLead
  } = useSelector(
    (state) => state.checkout
  );

  const id_turma = idTurma ?? course?.id_turma ?? '';

  const [installments, setInstallmentes] = useState([]);
  const [student, setStudent] = useState({});
  const [callBack, setCallBack] = useState({});
  const [continueSignIn, setContinueSignIn] = useState(false);
  const [openModalDownloadContract, setOpenModalDownloadContract] = useState(false);
  const [method, setMethod] = useState("credit")
  const [paymentPixStatus, setPaymentPixStatus] = useState("waiting");

  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const { clearCupom, cupomValidator, getCourseDetailsByTag, proccess, checkStudent, checkEmail } = checkoutActions;
  const { login } = authActions;

  const startInstallments = (course) => {
    const { valor_a_vista, valor_curso } = course;
    const newerInstallments = makeInstallments(valor_a_vista, valor_curso, method);
    setInstallmentes(newerInstallments);
  };

  const isProduction = process.env.NODE_ENV === 'production';

  useEffect(() => {
    if (!course?.id_curso) {
      dispatch(
        getCourseDetailsByTag(curso, id_turma, makeURLToken(), (payload) => {
          if (payload.course?.id_type_course_class === 2) {
            return history.push(`${isProduction ? '/checkout' : ''}/modular/${curso}/${id_turma}`);
          }

          startInstallments(payload.course)
          setChangeValues(payload.course.id_turma, "id_turma");
        }, () => {
          Object.assign(course, {
            is_error: true
          });
          const verifyIsModule =
              window.location.pathname.includes('mod') || window.location.pathname.includes('modular');
          const messageIsModule = verifyIsModule ? 'module' : 'course';
          history.push({
            pathname: '/nao-encontrado/',
            state: {  
              type: messageIsModule 
            },
          }); 
        })
      );
    } else {
      startInstallments(course);
    }
    // eslint-disable-next-line
  }, [curso, id_turma]);

  useEffect(()=>{
    const {cupom: cupomDesconto} = cupom
    if(method === "credit"){
      const cashValue = cupomDesconto !== undefined ? cupomDesconto?.percentage ? parseFloat(course.valor_a_vista - ((course.valor_a_vista * cupomDesconto.percentage) / 100)) : cupomDesconto?.valor_cupom_a_vista : course.valor_a_vista;
      const value = cupomDesconto !== undefined ? cupomDesconto?.percentage ? parseFloat(course.valor_curso - ((course.valor_curso * cupomDesconto.percentage) / 100)) : cupomDesconto?.valor_cupom : course.valor_curso;
      const totalInstallments = cupomDesconto?.pode_parcelar === 0 ? 1 : undefined;

      const newerInstallments = makeInstallments(
        cashValue,
        value,
        method,
        totalInstallments,
      );
      setInstallmentes([...newerInstallments]);
    }
    // eslint-disable-next-line
  },[method])

  useEffect(() => {
    if (values.afiliado && !['dados', 'endereco', 'pagamento', 'confirmacao', 'erro'].includes(values.afiliado)) {
      setCookie(values.afiliado);
    }
  }, [values.afiliado]);

  const setChangeValues = (value, key) => {
    setValues((oldValues) => ({ ...oldValues, [key]: value }));
  };

  const setContract = () => {
    setValues((oldValues) => ({ ...oldValues, contract: !values.contract }));
  };
  const setContractPrivacity = () => {
    setValues((oldValues) => ({ ...oldValues, contractPrivacity: !values.contractPrivacity }));
  };

  const setChangeTypeCredit = (value) => {
    if (value === 'pix') {
      setValues((oldValues) => ({
        ...oldValues,
        type: value,
        card: { ...cardInitialState },
        installments: 1,
        contract: false,
      }));
    } else if (value === 'debit') {
      setValues((oldValues) => ({ ...oldValues, type: value, installments: 1, contract: false }));
    } else {
      setValues((oldValues) => ({ ...oldValues, type: value, installments: 1, contract: false }));
    }
  };

  const setExpirion = (expiry) => {
    setValues((oldValues) => ({ ...oldValues, expiry }));
  };

  const setPhoneNumber = (phone_number) => {
    setValues((oldValues) => ({ ...oldValues, phone_number }));
  };

  const setCardValues = (value, key) => {
    setValues((oldValues) => ({ ...oldValues, card: { ...oldValues.card, [key]: value } }));
  };

  const removeCupom = () => {
    dispatch(
      clearCupom(values.curso, '', values.id_turma, () => {
        setValues({
          ...values,
          cupom: '',
          detailsCupom: {
            valid: false,
            cashValue: 0,
            value: 0,
          },
        });

        if (values.type === 'free') {
          setChangeTypeCredit('credit');
        }

        startInstallments(course);
      })
    )
  };

  const applyCupom = () => {
    if (!values.curso || !values.cupom) {
      return notification['error']({
        message: 'O cupom informado é inválido',
      });
    }

    dispatch(
      cupomValidator(values.curso, values.cupom, values.id_turma, (payload) => {
        const { cupom } = payload;
        
        if (!values?.installments) {
          setValues((oldValues) => ({ ...oldValues, installments: 1 }));
        }
        
        if (parseFloat(cupom.valor_cupom).toFixed(2) === '0.00') {
          setValues((oldValues) => ({
            ...oldValues,
            type: 'free',
            installments: 1,
            contract: false,
          }));
          const newerInstallments = makeInstallments(0, null, method, 1);
          setInstallmentes([...newerInstallments]);
        } else {
          const cashValue = cupom.percentage ? parseFloat(course.valor_a_vista - ((course.valor_a_vista * cupom.percentage) / 100)) : cupom.valor_cupom_a_vista;
          const value = cupom.percentage ? parseFloat(course.valor_curso - ((course.valor_curso * cupom.percentage) / 100)) : cupom.valor_cupom;

          const totalInstallments = cupom.pode_parcelar ? 12 : 1;
          const newerInstallments = makeInstallments(
            cashValue,
            value,
            method,
            totalInstallments
          );

          const detailsCupom = {
            valid: true,
            cashValue,
            value,
          };

          setValues((oldState) => ({ ...oldState, detailsCupom }));
          setInstallmentes([...newerInstallments]);
        }
      })
    );
  };

  const onUserSelectedModule = (module, callBack) => {
    const selectedModules = [...values.selectedModules];

    const exists = selectedModules.find(_module => Number(_module.id_curso) === Number(module.id_curso));
    if (!exists) {

      if (selectedModules.length === 3) {
        return callBack();
      }

      selectedModules.push(module);
      setValues(oldState => ({ ...oldState, selectedModules }));
    } else {
      const newSelectedModules = selectedModules.filter(_module => Number(_module.id_curso) !== Number(module.id_curso));
      setValues(oldState => ({
        ...oldState,
        selectedModules: newSelectedModules
      }));
    }
  };

  const onInstallmentsByModule = (callBack) => {
    const total = values.selectedModules.reduce((acc, module) => (acc + module.valor_curso), 0);

    Object.assign(course, {
      valor_a_vista: total,
      valor_curso: total,
      selectedModules: values.selectedModules
    });

    dispatch(checkoutActions.selectedModules(course));
    callBack();
  };

  const onFinish = (cb) => {
    const document_number = unformat(values.document_number);
    const phone_number = unformat(values.phone_number);
    const birth_date = formatBirthDate(values.birth_date);
    const instituicao_financeira = values.another_financial_instituition
      ? values.another_financial_instituition
      : values.instituicao_financeira;
    const afiliado = getCookie();
    const selectedModules = course.selectedModules
      ? course.selectedModules.map(_course => _course.id_curso)
      : [];

    const sendValues = {
      ...values,
      phone_number,
      birth_date,
      document_number,
      instituicao_financeira,
      afiliado,
      selectedModules,
      id_turma
    };

    const { expiration_month, expiration_year } = formatExpirionNumber(sendValues.expiry);
    const type = formatCardCreditType(values.installments, sendValues.type);

    sendValues.card = { ...sendValues.card, expiration_month, expiration_year, type };
    sendValues.card = encryptCard(sendValues.card);

    dispatch(
      proccess(sendValues, makeURLToken(), (response) => {
        setStudent(response?.processing?.extra?._aluno ?? {});
        const student = response?.processing?.extra?._aluno ?? {};
        const method = response?.processing?.extra?._order?.metodo_pagamento;
        const status = response?.processing?.extra?._cart?.status;
        const order = response?.processing?.extra?._order ? response?.processing?.extra?._order : {};
        const data = response?.processing?.extra?._order?.json_retorno
          ? JSON.parse(response?.processing?.extra?._order?.json_retorno)
          : {};
        let antifraud = {};
        if (data && data.details && data.details[0] && data.details[0].antifraud) {
          antifraud = data.details[0].antifraud;
        }
        let callback = {};

        if (values.type === 'debit') {
          const frontendCallback = `${window.location.href}`;
          callback = {
            redirectURL: data.redirect_url ?? null,
            issuerPaymentId: data.post_data?.issuer_payment_id ?? null,
            payerAuthenticationRequest: data.post_data?.payer_authentication_request ?? null,
            backendCallback: `${config.API_BASE}/v1/checkout/callback_debit?id_order=${order.id_order}&payment_id=${data.payment_id}&type=${values.type}&frontendCallback=${frontendCallback}`,
          };
        } else if (values.type === 'pix') {
          callback = {
            qrCode: data.qr_code_img,
            pixChave: data?.additional_data?.qr_code,
          };
        }

        setCallBack({ method, status, data, response, antifraud, order, callback, student });
        cb({ method, status, data, response, antifraud, order, callback, student });
      })
    );
  };

  const onStartCourse = () => {
    const hasStudent = student.email && student.documento;
    const {url} = getDataByDomain();

    if (hasStudent) {
      dispatch(
        login(
          student.email,
          student.documento,
          true,
          () => {
            window.location.href = `${url}/courses`;
          },
          () => {
            window.location.href = `${url}/auth/login`;
          }
        )
      );
    } else {
      window.location.href = `${url}/auth/login`;
    }
  };

  const onCheckStudent = (cb) => {
    if (values.document_number && values.email) {
      dispatch(
        checkStudent(
          unformat(values.document_number),
          values.email,
          makeURLToken(),
          (payload) => {
            const exist = payload?.id_usuario;
            cb({ exist });
          },
          () => {
            // Se der error na requisição para buscar um aluno, só permitir o usuário prosseguir, para não travar o cadastro.
            cb({ exist: false });
          }
        )
      );
    }
  };

  const onCheckEmail = (cb) => {
    const regexEmail = /\S+@\S+\.\S+/gi;
    const validEmail = regexEmail.test(values.email) && values.email.trim();
    if (validEmail && values.document_number.trim()) {
      dispatch(
        checkEmail(unformat(values.document_number), values.email, (payload) => {
          const validEmail = !payload;
          cb(validEmail);
        })
      );
    }
  };

  const onChangeCEP = (result) => {
    setValues((oldValues) => ({
      ...oldValues,
      street: result.logradouro,
      state: result.uf,
      city: result.localidade,
      district: result.bairro,
      ibge: result.ibge,
    }));
  };

  const onSaveByStep = (step) => {
    if (values.email && values.document_number && values.full_name) {
      const formData = {
        ...values,
        id_course_category: course.id_course_category,
        step,
      };

      dispatch(checkoutActions.saveByStep(formData));
    }
  };

  const storeG4 = (current, openAdressArea, paymentPixStatus = "waiting") => {
    const checkoutG4 = new CheckoutG4(window);
    const item = {
      item_name: course.curso,
      item_brand: 'Academia Rafael Toro',
      item_id: course.id_turma,
      price: course.valor_curso,
      item_category: course.category,
      item_cid: course.id_curso,
      item_fid: course.id_course_category,
      item_variant: course.category,
      quantity: 1
    };

    const [name, lastname] = getNameAndLastName(values.full_name);
    let userId = '';
    try {
      userId = user?.id_profile
        ? user?.id_profile
        : currentLead?.id_usuario
          ? currentLead?.id_usuario
          : ''
    } catch (e) {}

    const currentUser = {
      clientEmail: values.email,
      clientPhone: values.phone_number,
      clientFirstName: name,
      clientLastName: lastname ?? '',
      user_id: userId,
    };

    switch(current) {
      case 1:
        if(openAdressArea)
          checkoutG4.address({
            event: 'add_adress_info',
            ...currentUser,
            ecommerce: {
              items: [item]
            }
          });
        else
          checkoutG4.start({
            event: 'begin_checkout',
            ecommerce: {
              items: [item]
            }
          });
      break;
      case 2:
        checkoutG4.payment({
          event: 'add_payment_info',
          ...currentUser,
          ecommerce: {
            items: [item]
          }
        });
        break
      case 4:
        const { total, installmentPurchases }  = getFullCurrentValue(installments, values.installments);        
        const status = values.type === 'pix' && paymentPixStatus === "paid" ? 'APPROVED' : callBack.status;

        checkoutG4.confirmPayment({
          event: 'purchase',
          ...currentUser,
          ecommerce: {
            transaction_id: callBack?.order?.id_order ?? '',
            affiliation: 'Academia Rafael Toro',
            value: values.type === 'free' ? 0 : total,
            installmentPurchases,
            tax: '',
            shipping: '',
            coupon: values.cupom,
            paymentType: values.type,
            currency: 'BRL',
            items: [item]
          }
        }, status);
        break;
      default:
        return
    }
  }

  const sharedContext = {
    values,
    setValues,
    callBack,
    cupom: cupom.cupom,
    installments,
    course,
    brazilianStates,
    financialInstitions,
    applyCupomLoading,
    processLoading: proccessLoading,
    continueSignIn,
    openModalDownloadContract,
    userSelectedModules: values.selectedModules,
    onUserSelectedModule,
    onInstallmentsByModule,
    setOpenModalDownloadContract,
    setContract,
    setContractPrivacity,
    setContinueSignIn,
    setChangeValues,
    setCardValues,
    setExpirion,
    setPhoneNumber,
    setChangeTypeCredit,
    applyCupom,
    removeCupom,
    onFinish,
    onStartCourse,
    onCheckStudent,
    onCheckEmail,
    onChangeCEP,
    onSaveByStep,
    storeG4,
    method,
    setMethod,
    paymentPixStatus, 
    setPaymentPixStatus
  };

  return <CheckoutContext.Provider value={sharedContext}>{children}</CheckoutContext.Provider>;
};

export const useCheckout = () => {
  return useContext(CheckoutContext);
};

export default CheckoutProvider;
