import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// Constants
export const constants = {
    SUPORTED_BROWSERS: createSagaAction('SUPORTED_BROWSERS')
}


export const actions = {
    setModalBrowsersFlag: (next) => {
        return {
          type: constants.SUPORTED_BROWSERS.SUCCESS,
          next
        }
    },
}

const ACTION_HANDLERS = {
    // SUPORTED_BROWSERS.ACTION
    [constants.SUPORTED_BROWSERS.SUCCESS]: () => {
        return { browserModalFlag: true }
      },
}

export const initialState = {
    browserModalFlag: false,
}

export default createReducer(initialState, (state, action) => {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : { ...state }
})