import React from 'react';
import P from 'prop-types';

import './style/cupom.scss';

const propTypes = {
    children: P.any
};

const Cupom = ({ children }) => {
    return (
        <div className="container-pix">
            <div className="container-pix-body">
                {children}
            </div>
        </div>
    );
};

Cupom.propTypes = propTypes;
export default Cupom;