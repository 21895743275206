import config from '../config';

const gtm = () => {
  (function(w,d,s,l,i){
    w[l]=w[l]||[];
    
    w[l].push({
      'gtm.start':
      new Date().getTime(),event:'gtm.js'
    });

    w[l].push({
      'event': 'changeRoute'
    });
    
    var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),
    dl=l!=='dataLayer'?'&l='+l:'';
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', config.GTM_ID);
};

export default gtm;