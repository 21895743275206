import React, { useEffect, useState } from 'react'
import { getDataByDomain } from '../../utils'

import PropTypes from 'prop-types';

import { Col, Layout, Row, Button } from 'antd';
import Hamburger from 'hamburger-react';
import useDocumentSize from '../../hooks/useDocumentSize';
import StudentFilledIcon from './assets/StudentFilled';
import CollapsedHeader from './CollapsedHeader';

import config from '../../config';

const propTypes = {
  title: PropTypes.any,
  icon: PropTypes.any,
};

function Header() {
  const [ isOpen, setOpen ] = useState((false));
  const { Header: AntHeader } = Layout
  const useSize = useDocumentSize();
  const { logo, hasMenu } = getDataByDomain();

  useEffect(() => {
    const HTMLbody = document.getElementsByTagName('body');
    if(isOpen) {
     
      window.scroll(0,0)
      HTMLbody[0].classList.add('no-overflow')
    } else {
      HTMLbody[0].classList.remove('no-overflow')
    }
  }, [isOpen])

  return (
  <AntHeader className="checkout-header">
    <Row align="middle" className="checkout-header-content">
      <Col span={6}>
        <img
          src={logo}
          className="checkout-header-logo"
          alt="Logo"
        />
      </Col>
      {hasMenu && <Col offset={6} span={12}>
        {
          useSize.width >= 768 ?
          <nav className="checkout-menu-header">
            <a 
              href="https://www.academiarafaeltoro.com.br/cursos/"
              className="checkout-menu-header-element"
            >
              Cursos
            </a>
            <a
              href="https://www.academiarafaeltoro.com.br/conteudos/"
              className="checkout-menu-header-element"
            >
              Conteúdos
            </a>
            <a
              href="https://www.academiarafaeltoro.com.br/materiais/"
              className="checkout-menu-header-element"
            >
              Materiais
            </a>
            <a
              href="https://www.academiarafaeltoro.com.br/sobre/"
              className="checkout-menu-header-element"
            >
              Sobre
            </a>
            <Button
              icon={<StudentFilledIcon />}
              shape="round"
              className="checkout-header-button"
              href={config.PLATFORM_URL}
            >
              Área do aluno
            </Button>
          </nav>
            :
          <nav className="checkout-menu-header">
            <Hamburger
              toggled={isOpen}
              toggle={setOpen}
              color="white"
            />
            <CollapsedHeader isOpen={isOpen} />
          </nav>
        }
      </Col>}
    </Row>
  </AntHeader>
  )
}

Header.propTypes = propTypes;
export default Header;
