import React from 'react';
import StudentFilledIcon from './assets/StudentFilled';
import config from '../../config';

function CollapsedHeader({isOpen}) {
  function noScroll() {
    window.scrollTo(0, 0);
  }
  
  // add listener to disable scroll
  isOpen && window.addEventListener('scroll', noScroll);
  
  // Remove listener to re-enable scroll
  isOpen && window.removeEventListener('scroll', noScroll);

  const openClass = isOpen ? 'open' : '' 
  return (
    <nav className={`collapsed-header ${openClass}`}>
      <li>
        <ul>
          <a
            href="https://www.academiarafaeltoro.com.br/cursos/"
            className="checkout-menu-header-element"
          >
            Cursos
          </a>
        </ul>
        <ul>
          <a
            href="https://www.academiarafaeltoro.com.br/conteudos/"
            className="checkout-menu-header-element"
          >
            Conteúdos
          </a>
        </ul>
        <ul>
          <a
            href="https://www.academiarafaeltoro.com.br/materiais/"
            className="checkout-menu-header-element"
          >
            Materiais
          </a>
        </ul>
        <ul>
          <a
            href="https://www.academiarafaeltoro.com.br/sobre/"
            className="checkout-menu-header-element"
          >
            Sobre
          </a>
        </ul>
        <ul>
          <a
            href={config.PLATFORM_URL}
            className="checkout-menu-header-element"
          >
            Área do aluno <StudentFilledIcon />
          </a> 
        </ul>
      </li>
    </nav>
  );
}

export default CollapsedHeader;