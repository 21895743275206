import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import P from 'prop-types';

import { CheckoutContext } from '../shared/CheckoutProvider';
import config from '../../../config';
import { numberFormat } from '../../../helpers';
import defaultImg from '../../../img/user-default.png';

import './style/sideCard.scss';
import { CuponsArea } from './CuponsArea';

const propTypes = {
  current: P.number.isRequired,
  responseStatus: P.string.isRequired,
  showTotal: P.bool,
  paymentPixStatus: P.string
};

const SideCard = ({ current, responseStatus, showTotal = true, paymentPixStatus }) => {
  const { course, cupom, values, installments, method } = useContext(CheckoutContext);
  const currentInstallment = installments.find(
    (installement) => installement.key === values.installments
  );

  function renderCourseValues() {
    return(
      <>
        <Col xs={12}>
          <p className="price-card">Valor</p>
        </Col>
        <Col xs={12}>
          <p className="price-card t-end">                        
            {values.installments === 1 && method !== 'credit' ? (
              <del>R$ {numberFormat(course.valor_curso, 2)}</del>
            ) : (
              `R$ ${numberFormat(course.valor_curso, 2)}`
            )}
          </p>
        </Col>
      </>
    )
  }

  return (
    <>
      {!course || course?.is_error ? (
        <></>
      ) : (
        <Col span={8} style={{ marginBottom: '141px' }}>
          <div className="container-card-course">
            <div className={current === 5 ? 'failed-payment' : ''}></div>
            <div style={current === 5 ? { filter: 'grayscale(100%)' } : {}}>
              {course.img_capa ? (
                <img
                  className="main-img-card"
                  src={config.s3Url + course.img_capa}
                  width="100%"
                  alt={course.nome}
                />
              ) : (
                <img
                  className="img-conatiner-card"
                  src={defaultImg}
                  width="100%"
                  alt={course.nome}
                />
              )}
            </div>
            {((current === 4 && responseStatus === 'APPROVED') || paymentPixStatus === "paid") && (
              <div className="container-card-confirmed">Confirmado!</div>
            )}
            <Col span={24} justifyContent="center">
              <div className="course-name">
                <p>{course.curso}</p>
              </div>
            </Col>

            {showTotal && current !== 4 && (paymentPixStatus === "waiting") && (
              <>
                <span className="divider" />
                {values.type !== 'free' && (
                  <Row className="row-price-card">
                    {course.valor_a_vista !== course.valor_curso ? renderCourseValues() : values.installments > 1 || values.installments === "" ? renderCourseValues() : null}
                    {values.installments === 1 && method !== 'credit' ? (
                      <>
                        <Col xs={12}>
                          <p className="price-card">Valor {method === 'debit' ? "Débito" : method === 'pix' ? "Pix" : ""}</p>
                        </Col>
                        <Col xs={12}>
                          <p className="price-card t-end">
                            R$ {numberFormat(course.valor_a_vista, 2)}
                          </p>
                        </Col>
                      </>
                    ) : null}
                    {cupom && cupom.percentage ? (
                      <>
                        <Col xs={12}>
                          <p className="price-card">
                            Cupom {cupom?.percentage && `${cupom.percentage}%`}
                          </p>
                        </Col>
                        <Col xs={12}>
                          {cupom?.id_desconto ? (
                            <p className="price-card t-end">
                              {method === 'credit' ? (
                                <span>{cupom.percentage
                                ? `- R$ ${numberFormat((course.valor_curso * cupom.percentage) / 100)}` : `R$ ${numberFormat(values.detailsCupom.value)}`}</span>
                              ):(
                                <span>{cupom.percentage
                                  ? `- R$ ${numberFormat((course.valor_a_vista * cupom.percentage) / 100)}` : `R$ ${numberFormat(values.detailsCupom.cashValue)}`}</span>
                              )}
                            </p>
                          ) : (
                            <p className="price-card t-end cupom-default">--</p>
                          )}
                        </Col>
                      </>
                    ) : null}
                    {currentInstallment?.key > 1 && method === 'credit' ? (
                      <>
                        <Col xs={12}>
                          <p className="price-card">Parcelas</p>
                        </Col>
                        <Col xs={12}>
                          <p className="price-card t-end">
                            <span>{currentInstallment.key}x R$ </span>
                            {numberFormat(currentInstallment.value, 2)}
                          </p>
                        </Col>
                      </>
                    ) : null}
                    {cupom?.cashback_percentage ? (
                      <>
                        <Col xs={12}>
                          <p className="price-card">Cashback {cupom.cashback_percentage}%</p>
                        </Col>
                        <Col xs={12}>
                          <p className="price-card t-end">
                            R${' '}
                            {numberFormat(
                              (course.valor_a_vista * cupom.cashback_percentage) / 100,
                              2
                            )}
                          </p>
                        </Col>
                      </>
                    ) : null}
                    {cupom?.cashback && !cupom.cashback_percentage ? (
                      <>
                        <Col xs={12}>
                          <p className="price-card">Cashback</p>
                        </Col>
                        <Col xs={12}>
                          <p className="price-card t-end">R$ {numberFormat(cupom.cashback, 2)}</p>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                )}

                <Row className="row-total-price-card">
                  <Col xs={4}></Col>
                  <Col xs={20}>
                    {!currentInstallment ? (
                      <p className="total-price-card-number t-end">
                        <span>R$ {cupom?.valor_cupom_a_vista ? numberFormat(cupom.valor_cupom_a_vista) : numberFormat(course.valor_curso)}</span>
                      </p>
                    ) : currentInstallment && method === "credit" ? (
                      <p
                        className={`total-price-card-number t-end ${
                          values.type === 'free' ? 'total-free' : ''
                        }`}
                      >
                        <span>R$ </span>
                        {cupom?.percentage ? 
                          numberFormat(course.valor_curso - (course.valor_curso * (cupom?.percentage / 100))) : 
                          cupom?.valor_cupom ? numberFormat(cupom.valor_cupom) : numberFormat(course.valor_curso)
                        }
                      </p>
                    ) : (
                      <p className="total-price-card-number t-end">
                        <span>R$ 
                          {cupom?.percentage ? 
                            numberFormat(course.valor_a_vista - (course.valor_a_vista * (cupom?.percentage / 100))) : 
                            cupom?.valor_cupom_a_vista ? numberFormat(cupom.valor_cupom_a_vista) : numberFormat(course.valor_a_vista)
                          }
                        </span>
                      </p>
                    )}
                  </Col>
                </Row>
                {course?.valor_curso < config.MAX_INSTALLMENTS || course?.coupon !== 1 ? null : (<><span className="divider" /><CuponsArea /></>)}
              </>
            )}
          </div>
        </Col>
      )}
    </>
  );
};

SideCard.propTypes = propTypes;
export default SideCard;
