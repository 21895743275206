import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';

import useDocumentSize from '../../../../hooks/useDocumentSize';
import { CheckoutContext } from '../../shared/CheckoutProvider';
import qrPix from '../../../../img/qrPix.png';
import { countPix } from '../../shared/constants';
import Cupom from '../Cupom';
import '../style/pix.scss';
import { verifyPixPayment } from '../../../../redux/api/checkout';

const PaymentPix = ({ onFinish }) => {
  const useSize = useDocumentSize();
  const [count, setCount] = useState(countPix);
  const interval = useRef(null);
  const keyPix = useRef(null);

  const { callBack, course, processLoading, setPaymentPixStatus } = useContext(CheckoutContext);

  const decrease = () => {
    setCount(prev => prev - 1000);
  }

  const renderCount = () => {
    const min = Math.floor((count/1000/60) << 0);
    const sec = Math.floor((count/1000)%60); 
    return `0${min}:${sec !== 0 ? sec : '00'}`;
  }

  useEffect(() => {
    interval.current = setInterval(decrease, 1000);
    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (count === 0) {
      setCount(0);
      clearInterval(interval.current);
    }

    (async () => {
      const sec = Math.floor((count/1000)%60); 
      const id_order = callBack?.order?.id_order;
      if(sec%10 === 0 && id_order !== undefined) {
        const returnPix = await verifyPixPayment(id_order);
        const {status} = returnPix;
        if(status)
          setPaymentPixStatus("paid")
      }
    })()

    if(count === 1000){
      setPaymentPixStatus("error")
    }
  
    // eslint-disable-next-line
  }, [count, setPaymentPixStatus]);

  useEffect(() => {
    if (callBack.status === 'PROCESSING') {
      setCount(countPix);
    }
  }, [callBack.status]);

  const copyCode = () => {
    copy(callBack?.callback?.pixChave);
  };

  const handleQRCode = () => {
    onFinish();
  };

  return !callBack.status ? (
      <>
        <Cupom>
          <Button className="btn-button btn-generate-pix" onClick={handleQRCode}>
          <div className="btn-content-process">
              {!processLoading ? (
                  <>
                    <span className="btn-content-process-text">
                      Gerar código Pix
                    </span>
                  </>
              ) : ( 
                <Spin indicator={<LoadingOutlined spin />} /> )}
          </div>
          </Button>
        </Cupom>
      </>
    ) : (
      <>
        <Row>
        <div className="container-method-payment">
        <Col className="gutter-row" xs={24} md={12}>
          <div className="card-pix">
            {useSize.width >= 767 && (
              <div className="card-body-pix">
                <p className="title-card-pix">
                  Pague com a chave pix
                </p>
                {
                  count ? (
                    <input disabled={!!count} className="text-copy"  ref={keyPix} value={callBack?.callback?.pixChave} />
                  ) : (
                    <input className="text-copy text-copy-disabled" value={callBack?.callback?.pixChave} />
                  )
                }
              </div>
            )}

            <div className="card-body-pix">
              {
                count ? (
                  <button className="button-card-pix" onClick={copyCode}>
                    Copiar código pix
                  </button>
                ) : (
                  <button className="button-card-pix button-card-pix-disabled">
                    Copiar código pix
                  </button>
                )
              }        
              <p className="time-cod-pix">
                Seu código expira em:
              </p>
              <p className="time-cod-pix-num">{renderCount()}</p>
            </div>
          </div>
        </Col>
        {useSize.width >= 768 && (
          <div>
            <div className="card-pix">
              <Row className="card-body-pix">
                <p className="title-card-pix-qr">
                  Ou aponte a câmera do seu celular para o <strong> QR Code </strong> para realizar o pagamento.
                </p>
                {
                  count 
                    ? <img src={`data:image/png;base64,${callBack?.callback?.qrCode}`} alt="qr code pix" width="60%" />
                    : <img src={qrPix} className="pix-disabled" alt="qr code pix"/>
                }
              </Row>
            </div>
          </div>
        )}
        </div>
        <Col xs={24} md={24} lg={24}>
          <div className="card-checkout-info">
            <div className="card-checkout-info-inner">
              <p>
                Após o pagamento esta será sua estreia no curso {course.curso}!
              </p>
              <br/>
              <p>As informações de login serão enviadas para o seu email cadastrado!</p>
              <br/>
              <p>
                O seu login será o seu e-mail cadastrado e sua senha corresponde ao seu CPF (somente os números).
              </p>
              <br/>
              <p>
                Você também receberá estas informações por e-mail. Caso a mensagem não esteja na sua caixa de entrada, verifique a sua pasta de spam.
              </p>
              <br/>
              <p>
                Em caso de dúvidas entre em contato pelo nosso <span>WhatsApp (51) 9744-9565</span>
              </p>
            </div>
          </div>
        </Col>
        </Row>  
      </>
  );
};

export default PaymentPix;
