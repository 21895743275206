export default {
    colors: {
        transparent: '',
        primary: '#000A66',
        success: '#12C735',
        warning: '#F89C3A',
        default: '#9A9A9A',
        danger: '#e51616',
        pink: '#D81E5B',
        white: '#FFFFFF',
        lilac: '#5768FF',

        gray_25: '#F8F8F8',
        gray_50: '#F6F6F6',
        gray_75: '#EAEAEA',
        gray_90: '#EFEFEF',
        gray_95: '#FFF4E9',
        gray_100: '#DDDDDD',
        gray_125: '#D6D6D6',
        gray_150: '#D9D9D9',
        gray_300: '#ccc',
        gray_500: '#252525',
        gray_600: '#666',
        gray_700: '#999',
        gray_800: '#858585',

        blue_400: '#000A66',
        blue_500: '#000533',
        blue_600: '#222B82',

        red_300: '#D81E5B',
        red_500: '#E55353'
    },
    media: {
        mobile: 375,
        tablet: 768,
        notebook: 1500,
    }
};
