import React from 'react'

import styled from 'styled-components';

import LogoLoading from '../../img/svg/loading.svg';

export function Loading() {
    return (
        <LoadingArea>
            <LoadingImage url={LogoLoading}>
                <div></div>
            </LoadingImage>
            <h1>Carregando</h1>
        </LoadingArea>
    );
}

interface LoadingProps {
    url: string;
}

export const LoadingArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);

    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
        color: var(--primary);
    }
`;

export const LoadingImage = styled.div<LoadingProps>`
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;

    div {
        background: url(${(props) => props.url});
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    div:after,
    div:before {
        content: ' ';
        position: absolute;
        display: block;
        width: 100px;
        height: 100px;
        background: url(${(props) => props.url});
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
    }

    @keyframes lds-heart {
        0% {
            transform: scale(0.95);
        }
        5% {
            transform: scale(1.1);
        }
        39% {
            transform: scale(0.85);
        }
        45% {
            transform: scale(1);
        }
        60% {
            transform: scale(0.95);
        }
        100% {
            transform: scale(0.9);
        }
    }
`;
