import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'

import history from './history'
import AppRoutes from './routes/AppRoutes'

import { ThemeProvider } from 'styled-components';
import theme from './shared/styled/theme';

import './styles/main.scss'

function App() {
  return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            <Router basename={process.env.PUBLIC_URL} history={history}>
              <AppRoutes />
            </Router>
          </PersistGate>
        </ThemeProvider>
      </Provider>
  )
}

export default App
