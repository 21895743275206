import React from 'react';
import Header from '../../../components/NewCheckout/Header';
import Footer from '../../../components/NewCheckout/Footer';

import BoxError from '../../../components/NewCheckout/components/BoxError';
import { useLocation } from 'react-router-dom';

export default function NotFound() {
    const { state }: any = useLocation();

    return (
        <>
            <Header />
            <BoxError type={state?.type || 'course'} />
            <Footer />
        </>
    );
}
