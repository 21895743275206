import styled, { css } from 'styled-components';


type Props = {
    layout?: 'warning' | 'primary' | 'outline-primary' | 'waiting' | 'default';
    icon: boolean;
    disabled?: boolean; 
};

export const Container = styled.button<Props>`
    box-sizing: border-box;
    border-radius: 4px;
    width: 176px;
    height: 45px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
    margin: 0px;
    padding: 0.1px;

    ${({ icon }) => icon && css `
        justify-content: center;
        text-align:center;
        align-itens: center;
    `}    

    ${({ theme, layout }) => {
        switch(layout) {
            case 'outline-primary':
                return css `
                    background-color: transparent;
                    border: 1px solid ${theme.colors.primary};
                    color: ${theme.colors.primary};
                `;
            case 'warning':
                return css`
                    background-color: ${theme.colors.warning};
                    border: none;
                    color: white;
                `;  
            default:
                return css`
                    background-color: ${theme.colors.default};
                    border: none;
                    color: white;
                `;  
        }
    }}

    transition: filter 0.2s;

    &:hover {
        filter: brightness(0.8);
    }

    ${({ disabled }) => disabled && css `
        cursor: not-allowed;
    `}

    svg {
        color: white;
    }
`;