import { all, fork } from 'redux-saga/effects'

import { rootSaga as auth } from './auth'
import { rootSaga as checkout } from './checkout'


function* rootSaga() {
  yield all( [
    fork(auth),
    fork(checkout),
  ])
};

export default rootSaga
