import { makeURLToken } from '../../components/NewCheckout/shared/functions';
import request from '../../shared/request';

export function cupomValidator(courseTag, cupom, courseClassId) {
    const _tag = encodeURIComponent(courseTag);
    const _cupom = encodeURIComponent(cupom);
    
    return request(`/v1/checkout/cupom/validate/${_tag}/${_cupom}/${courseClassId}`, {
      method: 'GET',
    }, {'x-api-key': makeURLToken()});
};

export function getCourseDetailsByTag(courseTag, id_turma, token) {
    return request(`/v1/checkout/prepare?tag=${courseTag}&id_turma=${id_turma}`, {
      method: 'GET',
    }, {'x-api-key': token});
};

export function proccess(formData, token) {
  const type = formData.type === 'credit' ? 'onetime' : 'start';
  const route = `/v1/checkout/process/${type}`;

  return request(route, {
    method: 'POST',
    body: JSON.stringify(formData)
  }, {'x-api-key': token});
};

export function findStudentByCPFAndEmail(cpf, email, token) {
  const route = '/v1/checkout/findStudent';
  return request(route, {
    method: 'GET',
    qsParams: {
      cpf,
      email
    }
  }, {'x-api-key': token});
};

export function findStudentByEmail(cpf, email) {
  const route = '/v1/checkout/userExists';
  return request(route, {
    method: 'GET',
    qsParams: { 
      cpf,
      email
    }
  }, {'x-api-key': makeURLToken()});
};

export function saveByStep(formData = {}) {
  const route = '/v1/checkout/save-by-step';

  return request(route, {
    method: 'POST',
    body: JSON.stringify(formData)
  }, {'x-api-key': makeURLToken()});
}

export function verifyPixPayment(id_order) {
  const route = `/v1/checkout/status/pix/${id_order}`;

  return request(route, {
    method: 'GET',
  }, {'x-api-key': makeURLToken()});
}
