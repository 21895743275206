import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// Constantes
export const constants = {
    CHECKOUT_CUPOM_CLEAR: createSagaAction('CHECKOUT_CUPOM_CLEAR'),
    CHECKOUT_CUPOM_VALIDATOR: createSagaAction('CHECKOUT_CUPOM_VALIDATOR'),
    CHECKOUT_COURSE_DETAIL: createSagaAction('CHECKOUT_COURSE_DETAIL'),
    CHECKOUT_PROCCESS: createSagaAction('CHECKOUT_PROCCESS'),
    CHECKOUT_CHECK_STUDENT: createSagaAction('CHECKOUT_CHECK_STUDENT'),
    CHECKOUT_CHECK_EMAIL: createSagaAction('CHECKOUT_CHECK_EMAIL'),
    CHECKOUT_USER_SELECTED_MODULES: 'CHECKOUT_USER_SELECTED_MODULES',
    CHECKOUT_SAVE_BY_STEP: createSagaAction('CHECKOUT_UPDATE_BY_STEP')
};

// Actions
export const actions = {
    clearCupom : (tagCourse, cupom, courseClassId, next) => ({
        type: constants.CHECKOUT_CUPOM_CLEAR.ACTION,
        tagCourse,
        cupom,
        courseClassId,
        next
    }),
    cupomValidator: (tagCourse, cupom, courseClassId, next) => ({
        type: constants.CHECKOUT_CUPOM_VALIDATOR.ACTION,
        tagCourse,
        cupom,
        courseClassId,
        next
    }),
    getCourseDetailsByTag: (courseTag, id_turma, token, next, error) => ({
        type: constants.CHECKOUT_COURSE_DETAIL.ACTION,
        courseTag,
        id_turma,
        token,
        next,
        error 
    }),
    proccess: (formData, token, next, nextError) => ({
        type: constants.CHECKOUT_PROCCESS.ACTION,
        formData,
        token,
        next,
        nextError
    }),
    checkStudent: (cpf, email, token, success, error) => ({
        type: constants.CHECKOUT_CHECK_STUDENT.ACTION,
        cpf,
        email,
        token,
        success,
        error
    }),
    checkEmail: (cpf, email, success, error) => ({
        type: constants.CHECKOUT_CHECK_EMAIL.ACTION,
        email,
        cpf,
        success,
        error
    }),
    selectedModules: (course) => ({
        type: constants.CHECKOUT_USER_SELECTED_MODULES,
        course
    }),
    saveByStep: (formData, success, error) => ({
        type: constants.CHECKOUT_SAVE_BY_STEP.ACTION,
        formData,
        success,
        error
    }),
};

// Reducers
const ACTION_HANDLERS = {

    // CHECKOUT_CUPOM_CLEAR.ACTION
    [constants.CHECKOUT_CUPOM_CLEAR.ACTION]: (state) => {
        return { ...state, error: null, isLoading: true, applyCupomLoading: true, }
    },
    [constants.CHECKOUT_CUPOM_CLEAR.SUCCESS]: (state, action) => {
        return { ...state, cupom: action.payload, isLoading: false, applyCupomLoading: false }
    },
    [constants.CHECKOUT_CUPOM_CLEAR.FAILED]: (state, action) => {
        return { ...state, error: action.message, isLoading: false, applyCupomLoading: false }
    },


    // CHECKOUT_CUPOM_VALIDATOR.ACTION
    [constants.CHECKOUT_CUPOM_VALIDATOR.ACTION]: (state) => {
        return { ...state, error: null, isLoading: true, applyCupomLoading: true, }
    },
    [constants.CHECKOUT_CUPOM_VALIDATOR.SUCCESS]: (state, action) => {
        return { ...state, cupom: action.payload, isLoading: false, applyCupomLoading: false }
    },
    [constants.CHECKOUT_CUPOM_VALIDATOR.FAILED]: (state, action) => {
        return { ...state, error: action.message, isLoading: false, applyCupomLoading: false }
    },

    // CHECKOUT_COURSE_DETAIL.ACTION
    [constants.CHECKOUT_COURSE_DETAIL.ACTION]: (state) => {
        return { ...state, error: null, isLoading: true }
    },
    [constants.CHECKOUT_COURSE_DETAIL.SUCCESS]: (state, action) => {
        return { 
            ...state, 
            course: action.payload.course,
            brazilianStates: action.payload.brazilianStates,
            financialInstitions: action.payload.financialInstitions,
            isLoading: false 
        }
    },
    [constants.CHECKOUT_COURSE_DETAIL.FAILED]: (state, action) => {
        return { ...state, error: action.message, isLoading: false }
    },

    // CHECKOUT_PROCESS.ACTION
    [constants.CHECKOUT_PROCCESS.ACTION]: (state) => {
        return { ...state, error: null, isLoading: true, proccessLoading: true }
    },
    [constants.CHECKOUT_PROCCESS.SUCCESS]: (state, action) => {
        return { ...state, process: action.payload, isLoading: false, proccessLoading: false }
    },
    [constants.CHECKOUT_PROCCESS.FAILED]: (state, action) => {
        const proccessLoading = action.message.includes('Ops! O seu pagamento está sendo processado') ? true : false;
        return { ...state, error: action.message, isLoading: false, proccessLoading }
    },

    // CHECKOUT_CHECK_STUDENT.ACTION
    [constants.CHECKOUT_CHECK_STUDENT.ACTION]: (state) => {
        return { ...state, error: null, isLoading: true }
    },
    [constants.CHECKOUT_CHECK_STUDENT.SUCCESS]: (state, action) => {
        return { ...state, student: action.payload, isLoading: false }
    },
    [constants.CHECKOUT_CHECK_STUDENT.FAILED]: (state, action) => {
        return { ...state, error: action.message, isLoading: false }
    },

    // CHECKOUT_CHECK_EMAL.ACTION
    [constants.CHECKOUT_CHECK_EMAIL.ACTION]: (state) => {
        return { ...state, error: null, isLoading: true }
    },
    [constants.CHECKOUT_CHECK_EMAIL.SUCCESS]: (state, action) => {
        return { ...state, checkEmail: action.payload, isLoading: false }
    },
    [constants.CHECKOUT_CHECK_EMAIL.FAILED]: (state, action) => {
        return { ...state, error: action.message, isLoading: false }
    },
    [constants.CHECKOUT_USER_SELECTED_MODULES]: (state, action) => {
        return { 
            ...state,
            course: { ...action.course }
        };
    },

    // CHECKOUT_UPDATE_BY_STEP
    [constants.CHECKOUT_SAVE_BY_STEP.ACTION]: (state) => {
        return {
            ...state,
            isLoadingByStep: true
        };
    },
    [constants.CHECKOUT_SAVE_BY_STEP.SUCCESS]: (state, action) => {
        return {
            ...state,
            isLoadingByStep: false,
            currentLead: action.payload
        };
    },
    [constants.CHECKOUT_SAVE_BY_STEP.FAILED]: (state) => {
        return {
            ...state,
            isLoadingByStep: false
        };
    }
};

export const initialState = {
    error: null,
    isLoadingByStep: false,
    isLoading: false,
    applyCupomLoading: false,
    proccessLoading: false,
    cupom: {},
    course: {},
    brazilianStates: [],
    financialInstitions: [],
    process: {},
    student: {},
    checkEmail: {},
    currentLead: {}
};

export default createReducer(initialState, (state, action) => {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : { ...state, isLoading: false }
});
