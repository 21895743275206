import React, { useContext } from 'react';
import P from 'prop-types';

import './style/topCard.scss';
import config from '../../../config';
import { numberFormat } from '../../../helpers';
import { Col, Row, Image} from 'antd';
import { CheckoutContext } from '../shared/CheckoutProvider';
import defaultImg from '../../../img/user-default.png';

import { CuponsArea } from './CuponsArea';

const propTypes = {
    current: P.number.isRequired,
    responseStatus: P.string.isRequired,
    showTotal: P.bool
};

const TopCard = ({ current, responseStatus, showTotal = true }) => {

    const { course, values, installments, cupom, method } = useContext(CheckoutContext);
    const currentInstallment = installments.find((installement) => installement.key === values.installments);

    return (
        <>
            {
                (!course || course?.id_error) ? (<></>) : (
                    <Row className={`card-price-xs-lg ${['DENIED', 'ERROR'].includes(responseStatus) ? 'card-price-xs-lg-error' : ''}`}>
                        <Col xs={12}>
                            <div className={current === 5 ? "failed-payment-xs-md" : ""}></div>
                            <div className='img-content' style={current === 5 ? { filter: 'grayscale(100%)' } : {}}>
                                {
                                    course.img_capa ? (
                                        <Image className="img-conatiner-card" src={config.s3Url + course.img_capa} />
                                    ) : (
                                        <Image className="img-conatiner-card" src={defaultImg} />
                                    )
                                }
                            </div>
                        </Col>
                        <Col xs={12}>
                            <h2 className="title-card-price-xs-lg">{course.curso}</h2>
                            {showTotal && (
                                <div className={`number-card-price-xs-lg ${values.type === 'free' ? 'total-free' : ''}`}>
                                    {!currentInstallment ? (
                                        <>
                                            <span>R$</span> 
                                            {cupom?.valor_cupom_a_vista ? numberFormat(cupom.valor_cupom_a_vista) : numberFormat(course.valor_curso)}
                                        </>
                                    ) : currentInstallment && method === "credit" ? (
                                        <>
                                            <span>{currentInstallment.key !== 1 ? `${currentInstallment.key} x` : ''} R$ </span>
                                            {numberFormat(currentInstallment.value)}
                                        </>
                                    ) : (
                                        <>
                                            <span>R$</span>  
                                            {cupom?.percentage ? 
                                                numberFormat(course.valor_a_vista - (course.valor_a_vista * (cupom?.percentage / 100))) : 
                                                cupom?.valor_cupom_a_vista ? numberFormat(cupom.valor_cupom_a_vista) : numberFormat(course.valor_a_vista)
                                            }
                                        </>
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                )
            }
            {course?.valor_curso < config.MAX_INSTALLMENTS || course?.coupon !== 1 ? null : <CuponsArea />}
        </>
    );
};

TopCard.propTypes = propTypes;
export default TopCard;
