import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { Spin } from 'antd'

import MainCheckout from '../pages/Checkout/Main';
import ModuleCheckout from '../pages/Checkout/Module';
import CheckoutNotFound from '../pages/Checkout/NotFound';
import gtm from "../shared/gtm";

function AppRoutes() {
  const locations = useLocation()
  const isProduction = process.env.NODE_ENV === 'production';

  useEffect(() => {
    gtm();
  }, [locations])
  
  return (
    <React.Suspense fallback={<Spin tip="Loading..." />}>
      <Switch>
          <Route path={`/nao-encontrado/`} component={CheckoutNotFound} />
          <Route path={`${isProduction ? '/:checkout' : ''}/modular/:curso/:id_turma?/:afiliado?`} component={ModuleCheckout} />
          <Route path={`${isProduction ? '/:checkout' : ''}/:curso/:id_turma?/:afiliado?`} component={MainCheckout} /> 
      </Switch>
    </React.Suspense>
  )
}

export default AppRoutes
